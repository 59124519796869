import React from 'react';

const Legal = () => (
  <div className="flex text-white mt-4 lg:flex-col">
    <div className="mr-4">©2020 Yozobi. All rights reserved</div>
    <a
      className="mr-4"
      href="https://docs.yozobi.com/legal/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
    <a
      href="https://www.yozobi.com/contact-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact
    </a>
  </div>
);

export default Legal;
