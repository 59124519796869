import React from 'react';
import { IPostProps } from '../../templates/post';
import { ITagProps } from '../../templates/tag';
import { navigate } from 'gatsby';

interface ITagComponentProps {
  post: IPostProps;
  limit?: number;
  primaryColour?: string;
  inline?: boolean;
  clickable?: boolean;
}

const Tags = ({
  post,
  limit,
  primaryColour,
  inline = true,
  clickable = false,
}: ITagComponentProps) => {
  const tagsToRender = post?.tags?.slice(0, limit) || [];

  return (
    <div
      className={`flex flex-wrap ${!inline &&
        'flex-col items-start md:flex-row'}`}
    >
      {tagsToRender.map((tag: ITagProps) => (
        <div
          className={`cursor-pointer rounded-md px-2 py-1 mr-2 mb-2 font-bold border-2 border-solid ${primaryColour}`}
          key={tag.slug}
          onClick={() => (clickable ? navigate(`/tag/${tag.slug}`) : null)}
        >
          {tag.name}
        </div>
      ))}
    </div>
  );
};

export default Tags;
