import React from 'react';
import { Navigation } from './';

const Header = () => (
  <header className="h-48 flex relative z-50 px-4 xs:px-0">
    <div className="max-w-md w-full m-auto px-2 flex justify-between items-center xs:px-0">
      <Navigation />
    </div>
  </header>
);

export default Header;
