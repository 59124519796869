import React from 'react';
import Logo from './Logo';
import Contact from './Contact';
import Legal from './Legal';
import FooterNavigation from './FooterNavigation';

const Footer = () => (
  <footer className="bg-smoke-100 text-white p-16 md:p-8">
    <div className="max-w-lg mx-auto w-full flex justify-between md:flex-col md:items-center">
      <div className="flex flex-col justify-between mb-4 md:order-2">
        <Logo />
        <Legal />
      </div>
      <div className="flex justify-between w-1/2 md:w-auto md:flex-col md:order-1">
        <Contact />
        <FooterNavigation />
      </div>
    </div>
  </footer>
);

export default Footer;
