import React, { useState, useRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SVGMenu from '../../svgs/SVGMenu';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';

export const AUTHOR_QUERY = graphql`
  query mobileAuthor {
    ceo: ghostAuthor(slug: { eq: "dan" }) {
      postCount
      slug
    }
    cto: ghostAuthor(slug: { eq: "richard" }) {
      postCount
      slug
    }
  }
`;

interface IAuthorDataProps {
  ceo?: {
    postCount: number;
    slug: string;
  };
  cto?: {
    postCount: number;
    slug: string;
  };
}

const MobileNavigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const authorQuery: IAuthorDataProps = useStaticQuery(AUTHOR_QUERY);
  const ceo = Boolean(authorQuery?.ceo?.postCount);
  const cto = Boolean(authorQuery?.cto?.postCount);

  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsMenuOpen(false));

  return (
    <div className="md:static relative mr-8">
      <button
        className="items-center hidden md:flex"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <SVGMenu />
      </button>
      {isMenuOpen && (
        <nav
          ref={ref}
          className="absolute mt-8 p-4 right-0 animated-popover hidden flex-col w-full bg-purple-900 shadow-2xl md:flex"
        >
          <Link
            to="/"
            className="nav-item text-center hover:text-purple-900 hover:bg-green-500"
          >
            Home
          </Link>
          {ceo && (
            <Link
              to="/author/dan/"
              className="nav-item text-center hover:text-purple-900 hover:bg-green-500"
            >
              CEO Blog
            </Link>
          )}
          {cto && (
            <Link
              to="/author/richard/"
              className="nav-item text-center hover:text-purple-900 hover:bg-green-500"
            >
              CTO Blog
            </Link>
          )}
          <a
            href="https://www.yozobi.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-item text-center hover:text-purple-900 hover:bg-green-500"
          >
            Website
          </a>
        </nav>
      )}
    </div>
  );
};

export default MobileNavigation;
