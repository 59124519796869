import React from 'react';

const SVGPhone = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none">
    <path
      d="M17.4873 14.14L13.4223 10.444C13.2301 10.2693 12.9776 10.1762 12.7181 10.1842C12.4585 10.1922 12.2123 10.3008 12.0313 10.487L9.63828 12.948C9.06228 12.838 7.90428 12.477 6.71228 11.288C5.52028 10.095 5.15928 8.934 5.05228 8.362L7.51128 5.968C7.69769 5.78712 7.80642 5.54082 7.81444 5.2812C7.82247 5.02159 7.72917 4.76904 7.55428 4.57699L3.85928 0.512995C3.68432 0.320352 3.44116 0.203499 3.18143 0.187255C2.92171 0.17101 2.66588 0.256653 2.46828 0.425995L0.298282 2.28699C0.125393 2.46051 0.0222015 2.69145 0.00828196 2.93599C-0.00671804 3.18599 -0.292718 9.108 4.29928 13.702C8.30528 17.707 13.3233 18 14.7053 18C14.9073 18 15.0313 17.994 15.0643 17.992C15.3088 17.9783 15.5396 17.8747 15.7123 17.701L17.5723 15.53C17.7423 15.333 17.8286 15.0774 17.8127 14.8177C17.7968 14.558 17.68 14.3148 17.4873 14.14Z"
      fill="#74D572"
    />
  </svg>
);

export default SVGPhone;
