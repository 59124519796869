import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SubscribePopover from './SubscribePopover';
import Logo from './Logo';
import MobileNavigation from './MobileNavigation';

export const AUTHOR_QUERY = graphql`
  query author {
    ceo: ghostAuthor(slug: { eq: "dan" }) {
      postCount
      slug
    }
    cto: ghostAuthor(slug: { eq: "richard" }) {
      postCount
      slug
    }
  }
`;

interface IAuthorDataProps {
  ceo?: {
    postCount: number;
    slug: string;
  };
  cto?: {
    postCount: number;
    slug: string;
  };
}

const Navigation = () => {
  const authorQuery: IAuthorDataProps = useStaticQuery(AUTHOR_QUERY);
  const ceo = Boolean(authorQuery?.ceo?.postCount);
  const cto = Boolean(authorQuery?.cto?.postCount);
  return (
    <nav className="flex items-center w-full relative xs:flex-wrap xs:justify-center">
      <Logo className="xs:w-full xs:flex xs:justify-center xs:mb-4" />
      <Link
        to="/"
        className="nav-item mr-8 hover:text-purple-900 hover:bg-green-500 md:hidden"
      >
        Home
      </Link>
      {ceo && (
        <Link
          to="/author/dan/"
          className="nav-item mr-8 hover:text-purple-900 hover:bg-green-500 md:hidden"
        >
          CEO Blog
        </Link>
      )}
      {cto && (
        <Link
          to="/author/richard/"
          className="nav-item mr-8 hover:text-purple-900 hover:bg-green-500 md:hidden"
        >
          CTO Blog
        </Link>
      )}
      <SubscribePopover />
      <a
        href="https://www.yozobi.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="nav-item text-purple-900 bg-green-500 hover:bg-pink-500 md:hidden"
      >
        Website
      </a>
      <MobileNavigation />
    </nav>
  );
};

export default Navigation;
