import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// @ts-ignore
import { readingTime as readingTimeHelper } from '@tryghost/helpers';
import { IPostProps } from '../../templates/post';
import Tags from './Tags';
import formatDate from '../../utils/formatDate';
import SVGClock from '../../svgs/SVGClock';

interface IPostCardProps {
  post: IPostProps;
  displayImage?: boolean;
  tagLimit?: number;
  primaryColour?: string;
  className?: string;
}

export const DEFAULT_FEATURE_IMAGE_QUERY = graphql`
  query {
    mountain: file(base: { eq: "mountain.jpg" }) {
      childImageSharp {
        thumb: fixed(width: 265, height: 298) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const PostCard = ({
  post,
  displayImage = true,
  tagLimit = 3,
  primaryColour = 'white',
  className,
}: IPostCardProps) => {
  const image = useStaticQuery(DEFAULT_FEATURE_IMAGE_QUERY);
  const url = `/${post.slug}/`;
  const readingTime = readingTimeHelper(post);

  const featureImg = post.featureImageSharp?.childImageSharp?.thumb;
  const defaultImage = image.mountain?.childImageSharp?.thumb;

  const imageThumb = featureImg ? featureImg : defaultImage;

  return (
    <Link
      to={url}
      className={`${
        displayImage
          ? 'max-w-postCardLg'
          : 'w-postCardSm flex-shrink-0 min-h-postCard'
      } w-full bg-purple-500 rounded-lg shadow-cards flex p-4 h-postCard block text-white m-4 sm:my-2 ${className}`}
    >
      <section
        className={`${
          displayImage ? 'w-1/2 xs:w-4/5' : 'w-full'
        } flex flex-col justify-between p-3 flex-shrink-0`}
      >
        <h3 className={`h-full font-bold ${primaryColour} text-2xl`}>
          {post.title}
        </h3>
        <div className="h-full">
          <div className="italic font-light mb-4">
            {formatDate(post.created_at)}
          </div>
          <Tags post={post} limit={tagLimit} primaryColour={primaryColour} />
        </div>
        <div className="flex h-full italic font-light">
          <div className="mt-auto flex items-center">
            <SVGClock primaryColour={primaryColour} />
            {readingTime ? readingTime : '1 min read'}
          </div>
        </div>
      </section>
      {displayImage && (
        <Img
          fixed={imageThumb}
          className="w-1/2 rounded-lg object-cover border-2 border-solid border-white"
          alt=""
        />
      )}
    </Link>
  );
};

export default PostCard;
