import React from 'react';

const SVGClock = ({ primaryColour }: { primaryColour?: string }) => (
  <svg width="2em" viewBox="0 0 33 33" fill="none" className="mr-2">
    <g clipPath="url(.clip0)">
      <path
        d="M16.9144 6.76855L14.9292 6.79199L15.0597 17.8414L22.2717 21.1659L23.1028 19.3626L17.0301 16.5634L16.9144 6.76855Z"
        fill="white"
      />
      <path
        d="M15.8941 1.84759C7.67157 1.94471 1.0841 8.68964 1.18122 16.9122C1.27833 25.1348 8.02326 31.7222 16.2458 31.6251C24.4684 31.528 31.0559 24.7831 30.9588 16.5605C30.8616 8.33795 24.1167 1.75048 15.8941 1.84759ZM16.2224 29.64C9.09563 29.7241 3.25056 24.0155 3.16639 16.8888C3.08221 9.762 8.79083 3.91693 15.9176 3.83276C23.0443 3.74859 28.8894 9.45721 28.9736 16.584C29.0578 23.7107 23.3491 29.5558 16.2224 29.64Z"
        className={`${primaryColour} fill-current`}
      />
    </g>
    <defs>
      <clipPath className="clip0">
        <rect
          x={0.000976562}
          y={1.04248}
          width={31.7649}
          height={31.7649}
          transform="rotate(-0.676664 0.000976562 1.04248)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVGClock;
