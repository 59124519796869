import React from 'react';
import SVGPhone from '../../svgs/SVGPhone';
import SVGMail from '../../svgs/SVGMail';
import SVGMessenger from '../../svgs/SVGMessenger';

const Contact = () => (
  <div className="flex flex-col font-bold mr-2 flex-shrink-0 md:mb-4">
    <div className="footer-item">
      <div className="w-10 h-10 rounded-full bg-white mr-4 flex items-center justify-center flex-shrink-0">
        <SVGPhone />
      </div>
      <a href="tel:03331230038">+44 333 123 0038</a>
    </div>
    <div className="footer-item">
      <div className="w-10 h-10 rounded-full bg-white mr-4 flex items-center justify-center flex-shrink-0">
        <SVGMail />
      </div>
      <a href="mailto:hello@yozobi.com">hello@yozobi.com</a>
    </div>
    <div className="footer-item">
      <div className="w-10 h-10 rounded-full bg-white mr-4 flex items-center justify-center flex-shrink-0">
        <SVGMessenger />
      </div>
      <a
        href="http://fb.me/msg/yozobi.social"
        target="_blank"
        rel="noopener noreferrer"
      >
        @yozobi.social
        <div className="font-normal text-sm -mt-1 ml-1">Facebook Messenger</div>
      </a>
    </div>
  </div>
);

export default Contact;
