import React from 'react';

const SVGMessenger = () => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none">
    <path
      d="M3.683 16.418C1.436 14.722 0 12.14 0 9.248C0 4.14 4.477 0 10 0C15.523 0 20 4.14 20 9.248C20 14.355 15.523 18.496 10 18.496C8.99898 18.4969 8.00273 18.3582 7.04 18.084L3.683 19.975V16.418ZM8.933 6.715L3.539 12.375L8.447 9.705L11.012 12.375L16.374 6.715L11.521 9.337L8.932 6.715H8.933Z"
      fill="#3196F3"
    />
  </svg>
);

export default SVGMessenger;
