import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Header from './Header';
import Footer from './Footer';

import '../../styles/tailwind.css';
import Signup from './SignUp/Signup';

// query
export const LAYOUT_SETTINGS_QUERY = graphql`
  query GhostSettings {
    ghostSettings {
      ...GhostSettingsFields
    }
    file(relativePath: { eq: "ghost-icon.png" }) {
      childImageSharp {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

interface ILayoutProps {
  children: React.ReactNode | React.ReactNode[];
  margin?: boolean;
}

export interface GhostSettingsProps {
  logo: string;
  lang: string;
  cover_image: string;
  title: string;
  description: string;
  twitter: string;
}

export interface GhostSettingsDataProps {
  ghostSettings: GhostSettingsProps;
}

const DefaultLayout = ({ children, margin = true }: ILayoutProps) => {
  const settingsQueryData: GhostSettingsDataProps = useStaticQuery(
    LAYOUT_SETTINGS_QUERY,
  );
  const ghostSettings = settingsQueryData.ghostSettings;

  return (
    <>
      <Helmet>
        <html lang={ghostSettings.lang} />
        <body className="bg-purple-900" />
      </Helmet>

      <Header />

      <main className={`flex flex-col items-center ${margin && 'px-4'}`}>
        {children}
      </main>
      <Signup />
      <Footer />
    </>
  );
};

export default DefaultLayout;
