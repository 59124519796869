import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

export const AUTHOR_FOOTER_QUERY = graphql`
  query authorFooter {
    ceo: ghostAuthor(slug: { eq: "dan" }) {
      postCount
      slug
    }
    cto: ghostAuthor(slug: { eq: "richard" }) {
      postCount
      slug
    }
  }
`;

interface IAuthorDataProps {
  ceo?: {
    postCount: number;
    slug: string;
  };
  cto?: {
    postCount: number;
    slug: string;
  };
}

const FooterNavigation = () => {
  const authorQuery: IAuthorDataProps = useStaticQuery(AUTHOR_FOOTER_QUERY);
  const ceo = Boolean(authorQuery?.ceo?.postCount);
  const cto = Boolean(authorQuery?.cto?.postCount);
  return (
    <div className="flex flex-col flex-shrink-0 md:mb-8">
      <Link to="/" className="footer-item md:justify-center">
        Home
      </Link>
      {ceo && (
        <Link to="/author/dan/" className="footer-item md:justify-center">
          CEO Blog
        </Link>
      )}
      {cto && (
        <Link to="/author/richard/" className="footer-item md:justify-center">
          CTO Blog
        </Link>
      )}
      <a
        href="https://www.yozobi.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="footer-item md:justify-center"
      >
        Website
      </a>
    </div>
  );
};

export default FooterNavigation;
