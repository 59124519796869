const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  theme: {
    extend: {
      colors: {
        primary: defaultTheme.colors.blue,
        info: defaultTheme.colors.teal,
        warning: defaultTheme.colors.orange,
        success: defaultTheme.colors.green,
        danger: defaultTheme.colors.red,
        purple: {
          300: '#1E243B',
          500: '#333F65',
          700: '#2C3554',
          900: '#282D4D',
        },
        green: {
          500: '#0FD9B9',
        },
        red: {
          500: '#E76581',
        },
        paleBlue: {
          100: '#ecf8fe',
          200: '#d5f0fb',
          300: '#c0e9f6',
          400: '#ace2f1',
          500: '#99dbea',
          600: '#55c4e2',
          700: '#1b9eca',
          800: '#0e678b',
          900: '#053247',
        },
        pink: {
          100: '#feebed',
          200: '#fbc5cc',
          300: '#f7a6b2',
          400: '#f08498',
          500: '#e76581',
          600: '#e52a4c',
          700: '#bb112b',
          800: '#860917',
          900: '#4a0309',
        },
        smoke: {
          100: 'rgba(0, 0, 0, 0.1)',
          200: 'rgba(0, 0, 0, 0.2)',
          300: 'rgba(0, 0, 0, 0.3)',
          400: 'rgba(0, 0, 0, 0.4)',
          500: 'rgba(0, 0, 0, 0.5)',
          600: 'rgba(0, 0, 0, 0.6)',
          700: 'rgba(0, 0, 0, 0.7)',
          800: 'rgba(0, 0, 0, 0.8)',
          900: 'rgba(0, 0, 0, 0.9)',
        },
        frost: {
          100: 'rgba(255, 255, 255, 0.1)',
          200: 'rgba(255, 255, 255, 0.2)',
          300: 'rgba(255, 255, 255, 0.3)',
          400: 'rgba(255, 255, 255, 0.4)',
          500: 'rgba(255, 255, 255, 0.5)',
          600: 'rgba(255, 255, 255, 0.6)',
          700: 'rgba(255, 255, 255, 0.7)',
          800: 'rgba(255, 255, 255, 0.8)',
          900: 'rgba(255, 255, 255, 0.9)',
        },
      },
      spacing: {
        '60': '14rem',
        '72': '18rem',
        '84': '21rem',
        '96': '24rem',
        '128': '36rem',
      },
      borderRadius: {
        md: '10px',
        xl: '25px',
      },
      maxWidth: {
        md: '1180px',
        lg: '1210px',
        postCardLg: '570px',
        postBody: '48rem',
        '1/2': '50%',
      },
      width: {
        '4/5': '80%',
        postCardSm: '248px',
        popOver: '470px',
        logo: '200px',
      },
      height: {
        postCardSm: '334px',
        signUpMessage: '112px',
      },
      fontFamily: {
        fira: ['Fira Sans'],
      },
      margin: {
        '-hpOverlap': '-400px',
      },
      boxShadow: {
        green: '0px 4px 18px rgba(15, 217, 185, 0.5)',
        signUp:
          '-12px -12px 16px rgba(52, 62, 96, 0.5), 12px 12px 16px rgba(26, 30, 52, 0.5)',
        cards: '0px 4px 60px rgba(0, 0, 0, 0.5)',
      },
      minHeight: {
        postCard: '320px',
        banner: '900px',
      },
    },
    screens: {
      xs: { max: '499px' },
      // => @media (max-width: 419px) { ... }

      sm: { max: '639px' },
      // => @media (max-width: 639px) { ... }

      md: { max: '767px' },
      // => @media (max-width: 767px) { ... }

      lg: { max: '1023px' },
      // => @media (max-width: 1023px) { ... }

      xl: { max: '1279px' },
      // => @media (max-width: 1279px) { ... }
    },
  },
  variants: {},
  plugins: [],
};
