// TODO add a try/catch or a polyfill for IE11
const formatDate = (date) => {
  var dateToFormat = new Date(date);
  var options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(
    dateToFormat,
  );
  return formattedDate;
};

export default formatDate;
