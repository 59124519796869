import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
// @ts-ignore
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Success from './Success';
import AlreadySignedUp from './AlreadySignedUp';
import SignUpError from './Error';

interface IMailChimpResponse {
  result: string;
  msg: string;
}

const Signup = () => {
  const [email, setEmail] = useState(``);
  const [state, setState] = useState<IMailChimpResponse>({
    result: ``,
    msg: ``,
  });

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    addToMailchimp(email, null, null)
      .then((data: IMailChimpResponse) => {
        setState(data);
      })
      .catch((error: IMailChimpResponse) => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.error(error);
      });
  };

  const msg = state.msg;
  const result = state.result;
  const success = msg.includes(`subscribing`);
  const alreadySignedUp = msg.includes(`already`);
  const otherError = result.includes(`error`);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const firstInput = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const shouldFocus = location?.hash === '#signup';

  useEffect(() => {
    if (shouldFocus && firstInput.current) {
      firstInput.current.focus();
    }
  }, [shouldFocus]);

  return (
    <div className="m-16 sm:m-8">
      <div className="max-w-md w-full mx-auto flex flex-col text-white px-12 py-8 md:px-6 md:py-4 rounded-lg shadow-signUp bg-purple-900">
        <h2 className="font-bold text-4xl" id="signup">
          Keep in touch
        </h2>
        <div className="mb-10">
          Sign up to stay updated on how we use the latest tech to power our
          custom solutions
        </div>
        <form className="flex flex-col items-end" onSubmit={handleSubmit}>
          <div className="w-full">
            {success ? (
              <Success />
            ) : alreadySignedUp ? (
              <AlreadySignedUp />
            ) : (
              <>
                {!!success && !!alreadySignedUp && otherError && (
                  <SignUpError />
                )}
                <div className="flex w-full md:flex-col">
                  <div className="flex flex-col w-1/2 pr-2 md:w-full md:pr-0 md:mb-4">
                    <label htmlFor="fullName">Full name</label>
                    <input
                      ref={firstInput}
                      type="text"
                      id="fullName"
                      className="p-4 rounded-lg text-black"
                    />
                  </div>
                  <div className="flex flex-col w-1/2 pl-2 md:w-full md:pl-0">
                    <label htmlFor="emailAddress">Email address</label>
                    <input
                      type="email"
                      id="emailAddress"
                      className="p-4 rounded-lg text-black mb-8"
                      onChange={handleEmailChange}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <button
            type="submit"
            className="nav-item bg-green-500 text-purple-900 shadow-green md:w-full"
            disabled={success || alreadySignedUp}
          >
            {success || alreadySignedUp ? 'Subscribed!' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
