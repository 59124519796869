import React from 'react';

const SVGMail = () => (
  <svg width="1em" height="1em" viewBox="0 0 19 14" fill="none">
    <path
      d="M18.0369 0.0441895H0.963495C0.802512 0.0443748 0.64818 0.107301 0.534371 0.219158C0.420561 0.331015 0.356571 0.482664 0.356445 0.640823V1.84109C0.356445 1.89639 0.417483 1.95986 0.467358 1.98552L9.44153 7.02156C9.46452 7.03446 9.49058 7.04115 9.51706 7.04092C9.54412 7.041 9.5707 7.0339 9.59401 7.02039L18.297 1.99042C18.3459 1.96359 18.4756 1.89266 18.5242 1.85999C18.5831 1.82056 18.6439 1.78486 18.6439 1.71416V0.64059C18.6438 0.482472 18.5797 0.330883 18.4659 0.219076C18.3521 0.107269 18.1978 0.0443747 18.0369 0.0441895Z"
      fill="#3196F3"
    />
    <path
      d="M18.5684 3.65084C18.545 3.63775 18.5186 3.63094 18.4917 3.63111C18.4648 3.63127 18.4384 3.6384 18.4152 3.65177L13.4902 6.49867C13.4705 6.50991 13.4537 6.52534 13.441 6.54382C13.4283 6.56231 13.42 6.58337 13.4167 6.60545C13.4133 6.62753 13.4151 6.65005 13.4219 6.67136C13.4287 6.69266 13.4402 6.71219 13.4557 6.72851L18.3815 11.9451C18.3956 11.9602 18.4127 11.9722 18.4318 11.9804C18.4509 11.9886 18.4715 11.9928 18.4924 11.9927C18.5326 11.9927 18.5712 11.9769 18.5996 11.949C18.6281 11.9211 18.6441 11.8832 18.6441 11.8436V3.78034C18.6442 3.75411 18.6373 3.72832 18.624 3.70558C18.6107 3.68284 18.5915 3.66395 18.5684 3.65084Z"
      fill="#3196F3"
    />
    <path
      d="M12.0709 7.45477C12.0476 7.42975 12.0162 7.41337 11.982 7.40836C11.9478 7.40335 11.9129 7.41001 11.8831 7.42724L9.90899 8.56847C9.79333 8.63413 9.66227 8.6691 9.5287 8.66996C9.39512 8.67082 9.26361 8.63753 9.14709 8.57337L7.40977 7.59827C7.38173 7.58258 7.34932 7.57606 7.31725 7.57967C7.28519 7.58328 7.25512 7.59682 7.23141 7.61834L0.634135 13.6304C0.61684 13.6463 0.603561 13.6659 0.595356 13.6877C0.587151 13.7096 0.584248 13.733 0.586878 13.7561C0.589508 13.7792 0.597598 13.8014 0.610503 13.8209C0.623408 13.8404 0.640772 13.8567 0.66121 13.8684C0.76381 13.9277 0.862848 13.9559 0.963548 13.9559H17.8624C17.892 13.956 17.9209 13.9475 17.9456 13.9315C17.9703 13.9155 17.9897 13.8928 18.0013 13.8661C18.0132 13.8395 18.0168 13.8101 18.0119 13.7816C18.0069 13.753 17.9935 13.7265 17.9733 13.7053L12.0709 7.45477Z"
      fill="#3196F3"
    />
    <path
      d="M5.77952 6.90696C5.797 6.89109 5.81043 6.8714 5.81873 6.84949C5.82703 6.82758 5.82997 6.80405 5.82731 6.78082C5.82465 6.75759 5.81647 6.73529 5.80342 6.71574C5.79038 6.69619 5.77283 6.67992 5.75221 6.66826L0.582785 3.76746C0.559715 3.75459 0.53361 3.7479 0.507081 3.74805C0.480553 3.7482 0.454529 3.7552 0.431615 3.76833C0.4087 3.78146 0.389697 3.80028 0.376506 3.82289C0.363315 3.84551 0.356398 3.87113 0.356448 3.89719V11.5085C0.35628 11.5376 0.364793 11.5661 0.380932 11.5904C0.397072 11.6148 0.420129 11.634 0.44725 11.6456C0.474371 11.6572 0.504366 11.6608 0.533522 11.6558C0.562677 11.6509 0.589714 11.6376 0.611285 11.6177L5.77952 6.90696Z"
      fill="#3196F3"
    />
  </svg>
);

export default SVGMail;
