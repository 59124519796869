import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import tailwindTheme from '../../../tailwind.config';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';

const SubscribePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsPopoverOpen(false));

  return (
    <div className="md:static relative mr-8">
      <button
        className="ghost-nav-item hover:text-purple-900 hover:bg-green-500"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        Subscribe
      </button>
      {isPopoverOpen && (
        <article
          ref={ref}
          className="absolute right-0 animated-popover md:w-full"
        >
          <div className="w-8 h-8 ml-auto mr-16 xs:ml-32 md:mr-32 transform rotate-45 translate-y-6 bg-green-500"></div>
          <div
            className="bg-purple-500 px-12 py-8 flex flex-col items-center w-popOver rounded-md shadow-2xl z-10 relative md:w-full"
            style={{
              backgroundImage: `linear-gradient(180deg, ${tailwindTheme.theme.extend.colors.green[500]}, ${tailwindTheme.theme.extend.colors.purple[500]})`,
            }}
          >
            <h3 className="font-semibold text-4xl text-purple-900 mb-8">
              Subscribe to our blog!
            </h3>
            <button
              onClick={() => navigate('/#signup')}
              className="bg-purple-900 text-white rounded-md py-2 px-5 mb-4 w-64"
            >
              Subscribe by email
            </button>
            <button
              onClick={() => navigate('/rss')}
              className="bg-purple-900 text-white rounded-md py-2 px-5 mb-8 w-64"
            >
              Subscribe by RSS
            </button>
          </div>
        </article>
      )}
    </div>
  );
};

export default SubscribePopover;
