import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SVGYozobi from '../../svgs/SVGYozobi';
import SVGLogo from '../../svgs/SVGLogo';

const Logo = ({ className }: { className?: string }) => (
  <Link to="/" className={`mr-auto block ${className}`}>
    <SVGLogo />
  </Link>
);

export default Logo;
